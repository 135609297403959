import { mapListIdToValue } from '@components/AddSettingtems/utils';
import { LIST_TYPES_SPECIAL } from '@constants/constant';
import { FIELD_FOR_DATING_ITEM_COUPON } from '@constants/setting-add-item';
import { cloneDeep, groupBy } from 'lodash';

export const updateCouponAmount = (state, action, decrease) => {
  state.couponList = state.couponList.map(item => {
    if (
      item.type === action.name &&
      !!(
        state.userParticipationItems[action.name] ||
        (state.userParticipationItems.length &&
          state.userParticipationItems.find(item => item.type === action.name))
      )
    ) {
      if (LIST_TYPES_SPECIAL.includes(action.name)) {
        const {
          prevSelectedValue,
          currentSelectedValue
        } = action.valueForSpecialType;
        if (decrease) {
          if (!item.isUsingCoupon[currentSelectedValue]) {
            if (prevSelectedValue === currentSelectedValue) {
              item.coupon[currentSelectedValue] -= 1;
              item.isUsingCoupon[currentSelectedValue] = true;
            } else {
              item.coupon[prevSelectedValue] += 1;
              item.coupon[currentSelectedValue] -= 1;
              item.isUsingCoupon[prevSelectedValue] = false;
              item.isUsingCoupon[currentSelectedValue] = true;
            }
          }
          item[
            FIELD_FOR_DATING_ITEM_COUPON.PREV_SELECTED_VALUE
          ] = prevSelectedValue;
          item[
            FIELD_FOR_DATING_ITEM_COUPON.CURRENT_SELECTED_VALUE
          ] = currentSelectedValue;
        } else {
          item.coupon[currentSelectedValue] += 1;
          item.isUsingCoupon[currentSelectedValue] = false;
          item[FIELD_FOR_DATING_ITEM_COUPON.PREV_SELECTED_VALUE] = null;
          item[FIELD_FOR_DATING_ITEM_COUPON.CURRENT_SELECTED_VALUE] = null;
        }
      } else {
        if (decrease) {
          if (!item.isUsingCoupon) {
            item.coupon -= 1;
            item.isUsingCoupon = true;
          }
        } else {
          item.coupon += 1;
          item.isUsingCoupon = false;
        }
      }
    }
    return item;
  });
};

export const getAppliedItem = (appliedItems, itemType) =>
  appliedItems.find(item => item.type === itemType);

export const getAppliedItemUserData = (appliedItems, itemType) => {
  const foundItem = getAppliedItem(appliedItems, itemType);
  return foundItem ? foundItem.user_data[0] : null;
};

const getItemIdInList = (participationItemList, key) => {
  const itemInList = participationItemList.find(item => item.type === key);
  return itemInList ? itemInList.id : 0;
};

export const getFieldInCouponList = (
  state,
  participationItemList,
  itemType,
  field
) =>
  state.couponList.find(
    item => item.id === getItemIdInList(participationItemList, itemType)
  )[field];

export const getUserData = (
  state,
  participationItemList,
  appliedItems,
  itemType,
  field
) =>
  !state.isInit
    ? getAppliedItemUserData(appliedItems, itemType)
    : getFieldInCouponList(state, participationItemList, itemType, field);

export const getPriceAndCouponList = (
  state,
  participationItemList,
  participationItemsData,
  appliedItems,
  originalUserParticipationItems
) => {
  const getListIdUserItem = itemType =>
    originalUserParticipationItems[itemType]
      ? originalUserParticipationItems[itemType].item_id
      : [];

  const allOwnedOptions = (itemType, listItemId) =>
    mapListIdToValue(listItemId, participationItemsData, itemType);

  const modifyListCoupons = (itemType, listItemId) => {
    const foundItem = getAppliedItem(appliedItems, itemType);
    let couponObj = groupBy([
      ...(foundItem ? foundItem.user_data : []),
      ...allOwnedOptions(itemType, listItemId)
    ]);
    for (let key in couponObj) {
      if (couponObj.hasOwnProperty(key)) {
        couponObj[key] =
          foundItem && foundItem.user_data[0] === key
            ? couponObj[key].length - 1
            : couponObj[key].length;
      }
    }
    return couponObj;
  };

  const getModifiedListCoupons = itemType =>
    modifyListCoupons(itemType, getListIdUserItem(itemType));

  const getCouponsUsageStatus = itemType => {
    const tempParticipationItemsData = cloneDeep(participationItemsData);
    if (
      tempParticipationItemsData.hasOwnProperty(itemType) &&
      LIST_TYPES_SPECIAL.includes(itemType)
    ) {
      const foundItem = getAppliedItem(appliedItems, itemType);
      let couponUsageStatusObj = groupBy([
        ...(foundItem ? foundItem.user_data : []),
        ...allOwnedOptions(itemType, getListIdUserItem(itemType))
      ]);
      for (let key in couponUsageStatusObj) {
        if (couponUsageStatusObj.hasOwnProperty(key)) {
          couponUsageStatusObj[key] =
            (foundItem && foundItem.user_data[0] === key) || false;
        }
      }
      return couponUsageStatusObj;
    }
  };

  const priceList = Object.keys(participationItemsData).map(key => ({
    id: getItemIdInList(participationItemList, key),
    price: participationItemsData[key].price,
    type: participationItemsData[key].type
  }));
  const couponList = Object.keys(participationItemsData).map(key => {
    const fieldValue = field =>
      getFieldInCouponList(state, participationItemList, key, field);

    const userData = field =>
      getUserData(state, participationItemList, appliedItems, key, field);

    return {
      id: getItemIdInList(participationItemList, key),
      coupon: LIST_TYPES_SPECIAL.includes(key)
        ? !state.isInit
          ? getModifiedListCoupons(key)
          : fieldValue(FIELD_FOR_DATING_ITEM_COUPON.COUPON)
        : !state.isInit
        ? (originalUserParticipationItems[key] &&
            originalUserParticipationItems[key].remainItem) ||
          0
        : fieldValue(FIELD_FOR_DATING_ITEM_COUPON.COUPON),
      type: participationItemsData[key].type,
      isUsingCoupon: LIST_TYPES_SPECIAL.includes(key)
        ? !state.isInit
          ? getCouponsUsageStatus(key)
          : fieldValue(FIELD_FOR_DATING_ITEM_COUPON.IS_USING_COUPON)
        : !state.isInit
        ? participationItemsData[key].is_applied_items
        : fieldValue(FIELD_FOR_DATING_ITEM_COUPON.IS_USING_COUPON),
      hasCoupon: !state.isInit
        ? participationItemsData[key].is_applied_items ||
          !!originalUserParticipationItems[key]
        : fieldValue(FIELD_FOR_DATING_ITEM_COUPON.HAS_COUPON),
      currentSelectedValue: userData(
        FIELD_FOR_DATING_ITEM_COUPON.CURRENT_SELECTED_VALUE
      ),
      prevSelectedValue: userData(
        FIELD_FOR_DATING_ITEM_COUPON.PREV_SELECTED_VALUE
      )
    };
  });

  return { priceList, couponList };
};
