import femaleCasualCool from '@assets/FaceType/femaleCasualCool.png';
import femaleCute from '@assets/FaceType/femaleCute.png';
import femaleCuteCool from '@assets/FaceType/femaleCuteCool.png';
import femaleFeminine from '@assets/FaceType/femaleFeminine.png';
import maleCasualCool from '@assets/FaceType/maleCasualCool.png';
import maleCute from '@assets/FaceType/maleCute.png';
import maleCuteCool from '@assets/FaceType/maleCuteCool.png';
import maleFeminine from '@assets/FaceType/maleFeminine.png';
import { LIST_TYPES_NOT_ALLOW_FOR_MALE, UserGender } from '@constants/constant';
import { uniq } from 'lodash';
import { isEmptyObject, isMale } from '@utils';

export const femaleImgFaceType = {
  1: femaleCasualCool,
  2: femaleCute,
  3: femaleCuteCool,
  4: femaleFeminine
};
export const maleImgFaceType = {
  1: maleCasualCool,
  2: maleCute,
  3: maleCuteCool,
  4: maleFeminine
};

export const bindingData = (data, gender) =>
  data.map(item => ({
    name: item.display_name,
    id: item.value,
    image:
      gender === UserGender.FEMALE
        ? femaleImgFaceType[item.value]
        : maleImgFaceType[item.value]
  }));

export const MIN_RANGE_HEIGHT = 4;
export const MIN_RANGE_AGE = 3;
export const MAX_RANGE_AGE = 6;
export const EYE_OPTION_VALUE = 'st_01';
export const SELECTED_COLOR = 'white';
export const UNSELECT_COLOR = '#DE9C4E';

export const heightOptions = listValuesHeight =>
  listValuesHeight &&
  listValuesHeight.map(item => ({
    label: item + 'cm',
    value: item
  }));

export const ageOptions = listValuesAge =>
  listValuesAge &&
  listValuesAge.map(item => ({
    label: item + '歳',
    value: item
  }));

// START
// ONLY use these functions below with the dating item that user could own multiple items
// getAllOwnedOptionsByType => get all userItems and appliedItems of a specific item with given type => all owned options
export const getAllOwnedOptionsByType = (
  userParticipationItems,
  appliedItems,
  participationItemsData,
  itemType
) => {
  const foundAppliedItem =
    appliedItems.length && appliedItems.find(item => item.type === itemType);

  const isUserParticipationItemExist = itemType =>
    (userParticipationItems &&
      !isEmptyObject(userParticipationItems) &&
      userParticipationItems[itemType]) ||
    userParticipationItems.length;

  const ownedOptions = [
    ...(isUserParticipationItemExist(itemType)
      ? Array.isArray(userParticipationItems)
        ? userParticipationItems
            .filter(item => item.type === itemType)
            .map(item => item.user_data[0])
        : participationItemsData[itemType]
        ? mapItemIdToValue(
            userParticipationItems,
            participationItemsData,
            itemType
          )
        : []
      : []),
    ...(foundAppliedItem ? foundAppliedItem.user_data : [])
  ];
  return uniq(ownedOptions);
};

export const mapItemIdToValue = (
  userParticipationItems,
  participationItemsData,
  itemType
) =>
  participationItemsData[itemType]
    ? participationItemsData[itemType].option_data
        .filter(option =>
          userParticipationItems[itemType] &&
          userParticipationItems[itemType].user_data
            ? userParticipationItems[itemType].user_data.includes(
                option.item_id
              )
            : userParticipationItems[itemType].item_id.includes(option.item_id)
        )
        .map(item => item.value)
    : [];

// get all values even duplicate
export const mapListIdToValue = (
  listItemId,
  participationItemsData,
  itemType
) =>
  listItemId.length
    ? listItemId.map(id => {
        return participationItemsData[itemType]
          ? participationItemsData[itemType].option_data.find(
              option => option.item_id === id
            ).value
          : null;
      })
    : [];

export const deleteTypesNotAllow = (gender, obj) => {
  if (isMale(gender)) {
    if (Array.isArray(obj)) {
      obj.filter(item => !LIST_TYPES_NOT_ALLOW_FOR_MALE.includes(item.type));
    } else {
      LIST_TYPES_NOT_ALLOW_FOR_MALE.forEach(item => {
        delete obj[item];
      });
    }
  }
};

export const getInitStates = (
  educationValues,
  latestUserOptions,
  itemsPrice,
  allOwnedOptions,
  field
) => {
  const isLatestOptionExist =
    latestUserOptions &&
    !isEmptyObject(latestUserOptions) &&
    latestUserOptions[field];
  const foundItem = isLatestOptionExist
    ? educationValues.find(item => item.value === latestUserOptions[field][0])
    : null;
  const itemPrice = itemsPrice ? itemsPrice[0].price : '';
  const saleOffPrice = (itemsPrice && itemsPrice[0].price_sale) || '';
  const isAllowToShowPrice = isLatestOptionExist
    ? !allOwnedOptions.includes(latestUserOptions[field][0]) ||
      !latestUserOptions[field][0]
    : true;
  const isShowDiscount =
    educationValues && educationValues.some(item => item.discount_type);

  return {
    selectedOption: isLatestOptionExist ? latestUserOptions[field][0] : null,
    selectedPrice: foundItem ? foundItem.price : itemPrice,
    saleOffPrice: isShowDiscount
      ? foundItem
        ? foundItem.discount_type
          ? foundItem.price_sale
          : null
        : saleOffPrice
      : null,
    isAllowToShowPrice,
    isShowSpecialCharacter: !isLatestOptionExist
  };
};
// END

export const isOwnedDatingItem = (datingItemData, userParticipationItems) => {
  if (!datingItemData) return false;
  const { type } = datingItemData;
  return (
    !!userParticipationItems[type] ||
    (userParticipationItems.length &&
      userParticipationItems.find(item => item.type === type))
  );
};

export const isShowCoupon = datingItemData => {
  if (!datingItemData) return false;
  const { coupon, hasCoupon } = datingItemData;
  return coupon !== null && hasCoupon;
};
