import { cloneDeep } from 'lodash';
import * as types from '../actions/types';
import { path } from 'ramda';
import {
  getAppliedItemUserData,
  getPriceAndCouponList,
  updateCouponAmount
} from '@redux/utils';
import { LIST_TYPES_SPECIAL } from '@constants/constant';

const INITIAL_LATEST_USER_OPTIONS = {
  age: null,
  body_type: null,
  annual_income: null,
  dating_time: null,
  education_group: null,
  face_preferences: null,
  dating_area: null,
  height: null
};

const INITIAL = {
  data: null,
  participationItemsData: null,
  userParticipationItems: null,
  participationItemList: null,
  needToPurchaseItems: null,
  needToPurchaseItemsPrice: null,
  userOwnedParticipationItems: null,
  participationDetail: null,
  appliedItems: null,
  isError: false,
  latestUserOptions: INITIAL_LATEST_USER_OPTIONS,
  isInit: false,
  priceList: [],
  couponList: []
};

const userParticipationItemsReducer = (state = INITIAL, action) => {
  switch (action.type) {
    case types.USER_PARTICIPATION_ITEMS_REQUESTED:
      return {
        ...state,
        error: null
      };
    case types.USER_PARTICIPATION_ITEMS_SUCCEEDED: {
      const participationDetail = path(
        ['data', 'data', 'participationDetail'],
        action
      );
      const participationItemsData = path(
        ['data', 'data', 'participationItems'],
        action
      );
      const originalParticipationItemList = path(
        ['data', 'data', 'itemList'],
        action
      );
      const appliedItems = path(['data', 'data', 'appliedItems'], action);
      const originalUserParticipationItems = path(
        ['data', 'data', 'userItems'],
        action
      );
      const userParticipationItemsForEditing = cloneDeep(
        originalUserParticipationItems
      );

      Object.keys(participationItemsData).forEach(key => {
        if (participationItemsData[key].is_applied_items) {
          if (Array.isArray(userParticipationItemsForEditing)) {
            const foundItem =
              userParticipationItemsForEditing.length &&
              userParticipationItemsForEditing.find(
                item => participationItemsData[key].item_id === item.item_id
              );
            if (foundItem) {
              foundItem.remainItem += 1;
            } else {
              userParticipationItemsForEditing.push({
                ...participationItemsData[key],
                remainItem: 1
              });
            }
          } else {
            if (userParticipationItemsForEditing.hasOwnProperty(key)) {
              userParticipationItemsForEditing[key].remainItem += 1;
            } else {
              userParticipationItemsForEditing[key] = {
                ...participationItemsData[key],
                remainItem: 1
              };
            }
          }
        }
      });

      const userParticipationItems = participationDetail.is_editing
        ? userParticipationItemsForEditing
        : originalUserParticipationItems;
      const participationItemList = originalParticipationItemList
        .flatMap(item => item.data)
        .map(item => {
          const appliedItemUserData = getAppliedItemUserData(
            appliedItems,
            item.type
          );

          return {
            ...item,
            currentSelectedValue: appliedItemUserData,
            prevSelectedValue: appliedItemUserData
          };
        });

      const { priceList, couponList } = getPriceAndCouponList(
        state,
        participationItemList,
        participationItemsData,
        appliedItems,
        originalUserParticipationItems
      );

      return {
        ...state,
        participationItemsData,
        userParticipationItems,
        participationItemList,
        firstTimePurchaseItem: path(['data', 'data', 'first_time_purchase_item'], action),
        saleOffImageUrl: path(['data', 'data', 'image_url'], action),
        needToPurchaseItems: path(['data', 'data', 'purchaseItems'], action),
        needToPurchaseItemsPrice: path(['data', 'data', 'totalPrice'], action),
        participationDetail,
        appliedItems,
        data: action.data.data,
        isInit: true,
        error: null,
        priceList,
        couponList
      };
    }
    case types.USER_PARTICIPATION_ITEMS_FAILED:
      return {
        ...state,
        data: null,
        isError: true,
        error: action.error
      };
    case types.UPDATE_USER_PARTICIPATION_ITEMS_REQUESTED:
      return {
        ...state,
        error: null
      };
    case types.UPDATE_USER_PARTICIPATION_ITEMS_FAILED:
      return {
        ...state,
        isError: true,
        error: action.error
      };
    case types.USER_OWNED_PARTICIPATION_ITEMS_REQUESTED:
      return {
        ...state,
        error: null
      };
    case types.USER_OWNED_PARTICIPATION_ITEMS_SUCCEEDED:
      return {
        ...state,
        userOwnedParticipationItems: action.data.data,
        error: null
      };
    case types.USER_OWNED_PARTICIPATION_ITEMS_FAILED:
      return {
        ...state,
        data: null,
        isError: true,
        error: action.error
      };
    case types.UPDATE_USER_PARTICIPATION_ITEMS: {
      const tempLatestUserOptions = { ...state.latestUserOptions };
      tempLatestUserOptions[action.name] = action.value;
      return {
        ...state,
        latestUserOptions: tempLatestUserOptions
      };
    }
    case types.UPDATE_DATING_ITEM_COUPON: {
      updateCouponAmount(state, action, true);

      return {
        ...state
      };
    }
    case types.DELETE_USER_PARTICIPATION_ITEM: {
      updateCouponAmount(state, action, false);
      const tempLatestUserOptions = { ...state.latestUserOptions };
      tempLatestUserOptions[action.name] = null;
      return {
        ...state,
        latestUserOptions: tempLatestUserOptions
      };
    }
    case types.UPDATE_COUPON_LIST: {
      state.couponList = state.couponList.map(item => {
        const foundItem = action.selectedItems.find(
          selectedItem => selectedItem.type === item.type
        );
        if (foundItem) {
          if (LIST_TYPES_SPECIAL.includes(item.type)) {
            const value = foundItem.option;
            item.coupon[value] =
              !!item.coupon[value] || item.coupon[value] === 0
                ? (item.coupon[value] += 1)
                : 0;
            item.isUsingCoupon[value] = true;
            item.prevSelectedValue = value;
            item.currentSelectedValue = value;
          } else {
            item.coupon = 0;
            item.isUsingCoupon = true;
          }
          item.hasCoupon = true;
        }
        return item;
      });

      return {
        ...state
      };
    }
    case types.CLEAR_LATEST_PARTICIPATION_ITEMS_DATA:
      return {
        ...state,
        latestUserOptions: INITIAL_LATEST_USER_OPTIONS,
        isInit: false
      };
    default:
      return state;
  }
};

export default userParticipationItemsReducer;
