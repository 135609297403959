export const POPUP_NAME = {
  BACK_PAGE: 'backPagePopup',
  HELP_POPUP: 'isHelpPopupVisible',
  REMAIN_ITEM: 'showModalRemainItem',
  DATING_ITEM: 'showModalDatingItem'
};

export const FIELD_FOR_DATING_ITEM_COUPON = {
  COUPON: 'coupon',
  IS_USING_COUPON: 'isUsingCoupon',
  HAS_COUPON: 'hasCoupon',
  CURRENT_SELECTED_VALUE: 'currentSelectedValue',
  PREV_SELECTED_VALUE: 'prevSelectedValue'
};
